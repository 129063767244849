import * as Actions from '../../actions/admin/usersData.action';

const initialState = {
    usersLoading: false,
    users: [],
    count: 0,
    pages: 0,
    limit: 0,
    page: 0
};

const usersData = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case Actions.USERS_DATA_LOADING:
            return { ...state, usersLoading: !state.usersLoading };
        case Actions.GET_USERS:
            return {
                ...state,
                users: payload.result,
                usersLoading: !state.usersLoading,
                pages: payload.pages,
                limit: payload.limit,
                page: payload.page
            };
        case Actions.EDIT_USER:
        case Actions.DELETE_USER:

        default:
            return state;
    }
};

export default usersData;
