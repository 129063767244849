import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from 'source/constants';
import { getToken } from 'AuthWrapper';

export const GET_SIDEBAR_WEBSITES_LOADING = 'GET_SIDEBAR_WEBSITES_LOADING';
export const GET_SIDEBAR_WEBSITES = 'GET_SIDEBAR_WEBSITES';
export const SET_WEBSITE_ID = 'SET_WEBSITE_ID';
export const SET_WEBSITE_ID_LOADING = 'SET_WEBSITE_ID_LOADING';

export const getSidebarWebsites = () => async (dispatch) => {
    const token = await getToken();
    // dispatch({
    //     type: GET_SIDEBAR_WEBSITES_LOADING
    // });
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.get(`${BASE_URL}/websites/get`);
        if (res.data) {
            dispatch({
                type: GET_SIDEBAR_WEBSITES,
                payload: res.data.result
            });
        }
    } catch (error) {
        toast.error('error in getting website');
        // dispatch({
        //     type: GET_SIDEBAR_WEBSITES_LOADING
        // });
    }
};

export const setDefaultWebsite = (websiteId) => async (dispatch) => {
    localStorage.setItem('websiteId', websiteId);
    dispatch({
        type: SET_WEBSITE_ID_LOADING
    });
    dispatch({
        type: SET_WEBSITE_ID,
        payload: websiteId
    });
};
