import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from 'source/constants';
import { getToken } from 'AuthWrapper';

export const ADD_WEBSITE_LOADING = 'ADD_WEBSITE_LOADING';
export const ADD_WEBSITE = 'ADD_WEBSITE';
export const GET_WEBSITES_LOADING = 'GET_WEBSITES_LOADING';
export const GET_WEBSITES = 'GET_WEBSITES';

export const GET_ANALYTICS_LOADING = 'GET_ANALYTICS_LOADING';
export const GET_ANALYTICS = 'GET_ANALYTICS';

export const addWebsite = (data) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: ADD_WEBSITE_LOADING
    });
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.post(`${BASE_URL}/website/add`, { ...data });
        if (res.data) {
            dispatch({
                type: ADD_WEBSITE
            });
            return res.data;
        }
    } catch (error) {
        toast.error(error.response.data.message);
        dispatch({
            type: ADD_WEBSITE_LOADING
        });
    }
};

export const evaluatePackage = (data) => async (dispatch) => {
    const token = await getToken();
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.post(`${BASE_URL}/website/evaluate-package`, { ...data });
        if (res.data) {
            if (res.data.result.free) {
                window.location.pathname = "/admin/dashboard"
            } else {
                return res.data;

            }
        }
    } catch (error) {
        toast.error(error.response.data.message);

    }
};

export const getWebsites = (data) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: GET_WEBSITES_LOADING
    });
    const params = {
        ...data
    };
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.get(`${BASE_URL}/websites/get`, { params });
        if (res.data) {
            dispatch({
                type: GET_WEBSITES,
                payload: res.data.result
            });
            return res.data
        }
    } catch (error) {
        toast.error('error in getting website');
        dispatch({
            type: GET_WEBSITES_LOADING
        });
    }
};

export const getUserAnalytics = () => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: GET_ANALYTICS_LOADING
    });
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.get(`${BASE_URL}/user/analytics`);
        if (res.data) {
            dispatch({
                type: GET_ANALYTICS,
                payload: res.data.result
            });
        }
    } catch (error) {
        toast.error('error in getting analytics of user');
        dispatch({
            type: GET_ANALYTICS_LOADING
        });
    }
};
