import { useRoutes } from 'react-router-dom';
import routes from './MainRoutes';
import { useSelector } from 'react-redux';
export default function ThemeRoutes() {
    const { auth } = useSelector((state) => state.auth);
    // AdminRoutes
    const routing = useRoutes(routes(auth));

    return routing;
}
