import * as Actions from '../actions/sidebar.actions';

const initialState = {
    websitesLoading: false,
    websites: [],
    websiteId: null,
    websiteIdLoading: false
};

const siderbar = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case Actions.GET_SIDEBAR_WEBSITES_LOADING:
            return {
                ...state,
                websitesLoading: !state.websitesLoading
            };
        case Actions.GET_SIDEBAR_WEBSITES:
            return {
                ...state,
                websites: payload,
                websitesLoading: false
            };
        case Actions.SET_WEBSITE_ID_LOADING:
            return {
                ...state,
                websiteIdLoading: !state.websiteIdLoading
            };
        case Actions.SET_WEBSITE_ID:
            return {
                ...state,
                websiteId: payload,
                websiteIdLoading: false
            };

        default:
            return state;
    }
};

export default siderbar;
