
import { Card, Typography } from '@mui/material'
import { ErrorBoundary } from 'react-error-boundary'
import React from 'react';

import { useLocation } from 'react-router-dom';
import { Box } from '@mui/system';

export default function ErrorHandler({ children }) {



    const { pathname } = useLocation()
    const originalPathname = React.useRef(pathname)
    function ErrorFallback({ error, resetErrorBoundary }) {


        React.useEffect(() => {
            if (pathname !== originalPathname.current) {
                resetErrorBoundary()
            }
        }, [pathname])

        return (
            <Box height='100vh' display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                <Card role="alert" sx={{
                    p: 2,
                    textAlign: 'center', maxWidth: 300, margin: 'auto'
                }} >
                    <Typography variant='h6' marginBottom={1}>Something went wrong</Typography>
                    <Typography variant='caption' fontWeight='regular'>Contact us at: support@allyright.com
                    </Typography>
                </Card>
            </Box>
        )
    }



    return (
        <ErrorBoundary
            onError={(error) => {
            }}
            FallbackComponent={ErrorFallback}
        >
            {children}
        </ErrorBoundary>
    )
}



