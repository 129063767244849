import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, Typography, Box } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <ButtonBase disableRipple component={Link} to={config.defaultPath}>
            <Logo />
        </ButtonBase>
       
    </Box>
);

export default LogoSection;
