import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from 'source/constants';
import { getToken } from 'AuthWrapper';
import { getWebsites } from './website.actions';
import { getUserWithoutLoading } from './auth.action';

export const ADD_CARD_LOADING = 'ADD_CARD_LOADING';
export const ADD_CARD = 'ADD_CARD';
export const GET_CARDS_LOADING = 'GET_CARDS_LOADING';
export const GET_CARDS = 'GET_CARDS';
export const DELETE_CARDS_LOADING = 'DELETE_CARDS_LOADING';
export const DELETE_CARDS = 'DELETE_CARDS';
export const GET_INVOICE_LOADING = 'GET_INVOICE_LOADING';
export const GET_INVOICE = 'GET_INVOICE';
export const CREATE_SUBSCRIPTION_LOADING = 'CREATE_SUBSCRIPTION_LOADING';
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_LOADING = 'CANCEL_SUBSCRIPTION_LOADING';
export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';

export const addStripeCard = (stripeCardToken) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: ADD_CARD_LOADING
    });
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.post(`${BASE_URL}/billing/add-source`, { stripeCardToken });
        if (res.data) {
            dispatch({
                type: ADD_CARD_LOADING
            });
            dispatch(getUserWithoutLoading({ page: 1, limit: 5 }))

            dispatch(getCards({ page: 1, limit: 5 }))
        }
    } catch (error) {
        toast.error('Card having error');
        dispatch({
            type: ADD_CARD_LOADING
        });
    }
};

export const getCards = (data) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: GET_CARDS_LOADING
    });
    try {
        const params = {
            ...data
        };
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.get(`${BASE_URL}/billing/sources`, { params });
        if (res.data) {
            dispatch({
                type: GET_CARDS,
                payload: res.data
            });

            return res.data
        }
    } catch (error) {
        toast.error(error.response.data.message);
        dispatch({
            type: GET_CARDS_LOADING
        });
    }
};
export const delCard = (cardId) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: DELETE_CARDS_LOADING
    });
    try {
        const params = {
            cardId
        };
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.delete(`${BASE_URL}/billing/remove-source`, { params });
        if (res) {
            dispatch({
                type: DELETE_CARDS,
                payload: cardId
            });
            toast.info('Card removed successfully!');
        }
    } catch (error) {

        toast.error(error.response.data.message);
        dispatch({
            type: DELETE_CARDS_LOADING
        });
    }
};

export const createSubscription = (websiteId, priceId) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: CREATE_SUBSCRIPTION_LOADING
    });
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.post(`${BASE_URL}/billing/create-subscription`, { websiteId, priceId });
        if (res.data) {
            dispatch({
                type: CREATE_SUBSCRIPTION_LOADING
            });
            toast.info('Subscribe successfully!');

            window.location.pathname = '/user/dashboard'
        }
    } catch (error) {
        toast.error(error.response.data.message);
        dispatch({
            type: CREATE_SUBSCRIPTION_LOADING
        });
    }
};

export const cancelSubscription = (websiteId) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: CANCEL_SUBSCRIPTION_LOADING
    });
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.post(`${BASE_URL}/billing/cancel-subscription`, { websiteId });
        if (res.data) {
            dispatch({
                type: CANCEL_SUBSCRIPTION_LOADING
            });
            dispatch(getWebsites());
            toast.info('Subscription cancel!');
        }
    } catch (error) {
        console.log('error', error.response.data.message);

        toast.error(error.response.data.message);
        dispatch({
            type: CANCEL_SUBSCRIPTION_LOADING
        });
    }
};

export const getUserInvoices = () => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: GET_INVOICE_LOADING
    });
    try {
        const params = {
            page: 1,
            limit: 10
        };
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.get(`${BASE_URL}/user/invoice`, { params });
        if (res.data) {
            dispatch({
                type: GET_INVOICE,
                payload: res.data.result
            });
        }
    } catch (error) {
        toast.error(error.response.data.message);
        dispatch({
            type: GET_INVOICE_LOADING
        });
    }
};


export const setDefaultCard = (cardId) => async (dispatch) => {
    const token = await getToken();

    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.post(`${BASE_URL}/billing/default-source`, { cardId });
        if (res.data) {
            toast.info(res.data.message);
        }
    } catch (error) {
        toast.error(error.response.data.message);
    }
};