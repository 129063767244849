import { combineReducers } from 'redux';
import auth from './reducers/auth.reducer';
import billing from './reducers/billing.reducer';
import website from './reducers/website.reducer';
import policy from './reducers/template.reducer';
import sidebar from './reducers/sidebar.reducer';
import userData from './reducers/admin/usersData.reducer';
import adminWebsites from './reducers/admin/websites.reducer';
import invoices from './reducers/admin/invoices.reducer';
import subscriptions from './reducers/admin/subscriptions.reducer';
import adminDashboard from './reducers/admin/adminDashboard.reducer';
import ccpa from './reducers/admin/ccpaRequest.reducer';

// reducer import
import customizationReducer from './customizationReducer';

const reducer = combineReducers({
    customization: customizationReducer,
    auth,
    billing,
    website,
    policy,
    sidebar,
    userData,
    adminWebsites,
    invoices,
    subscriptions,
    adminDashboard,
    ccpa
});

export default reducer;
