import * as React from "react";
import { useDispatch } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import { IconCopy } from "@tabler/icons";
import { getUserTemplates } from "store/actions/template.actions";
import { Box } from "@mui/system";
import TablePagination from "views/TableComponents/TablePagination";

const Templates = () => {
  const columns = [
    { id: 1, label: "Version", width: 100 },
    { id: 2, label: "Status", width: 100 },
    { id: 3, label: "Privacy Link", width: 150 },
    { id: 4, label: "Widget Link", width: 150 },
    { id: 4, label: "CCPA Link", width: 150 },
  ];
  const websiteId = localStorage.getItem("websiteId");

  const [data, setData] = React.useState({
    templates: [],
    pages: 0,
    count: 0,
  });

  const [page, setPage] = React.useState(1);
  const [templatesLoading, setTemplatesLoading] = React.useState(true);
  const dispatch = useDispatch();

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  function getData(rs, pg) {
    setTemplatesLoading(true);
    dispatch(
      getUserTemplates({
        limit: rs,
        page: pg,
        websiteId,
      })
    ).then((data) => {

      setData({
        templates: data.result,
        pages: data.pages,
        count: data.count,
      });
      setTemplatesLoading(false);

    }).catch(err => {
      setTemplatesLoading(false);
    });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getData(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    getData(event.target.value, page);
  };

  React.useEffect(() => {
    if (websiteId) {
      getData(rowsPerPage, page);
    } else {
      setTemplatesLoading(false)
    }
  }, [websiteId]);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", padding: "20px", mt: 3 }}>
      <Box display="flex" justifyContent="flex-end">
        <Button
          disabled={templatesLoading}
          variant="contained"
          color="primary"
          onClick={() => getData(rowsPerPage, page)}
        >
          Refresh
        </Button>
      </Box>

      <TableContainer sx={{ maxHeight: 440 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          style={{ tableLayout: "fixed" }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ width: column.width }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {templatesLoading ? (
              <>
                <TableRow>
                  <TableCell colSpan="5" align="center">
                    <CircularProgress size={32} />
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <>
                {data.templates.length > 0 ? (
                  data.templates.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell>{row.version}</TableCell>
                        <TableCell sx={{ textTransform: "capitalize" }}>
                          {row.status}
                        </TableCell>

                        <TableCell>
                          <Typography
                            noWrap={true}
                            sx={{
                              fontWeight: "bold",
                              textTransform: "capitalize",
                            }}
                          >
                            privacy link
                            <Tooltip title="copy privacy link to clipboard">
                              <IconButton
                                sx={{ p: 0 }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    row.privacyLink
                                  );
                                }}
                              >
                                <IconCopy />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            noWrap={true}
                            sx={{
                              fontWeight: "bold",
                              textTransform: "capitalize",
                            }}
                          >
                            widget link
                            <Tooltip title="copy widget link to clipboard">
                              <IconButton
                                sx={{ p: 0 }}
                                onClick={() => {
                                  navigator.clipboard.writeText(row.widgetLink);
                                }}
                              >
                                <IconCopy />
                              </IconButton>
                            </Tooltip>
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            noWrap={true}
                            sx={{
                              fontWeight: "bold",
                              textTransform: "capitalize",
                            }}
                          >
                            Ccpa link
                            {row.ccpaLink && (
                              <Tooltip title="copy ccpa link to clipboard">
                                <IconButton
                                  sx={{ p: 0 }}
                                  onClick={() => {
                                    navigator.clipboard.writeText(row.ccpaLink);
                                  }}
                                >
                                  <IconCopy />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan="5" align="center">
                      No templates found
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!templatesLoading ? (
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          count={data.pages}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      ) : (
        <></>
      )}
    </Paper>
  );
};

export default Templates;
