import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { app } from 'firebase-config';
import { ToastContainer } from 'react-toastify';
import { Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthWrapper from 'AuthWrapper';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import ErrorHandler from 'ui-component/ErrorHandler';
import React from 'react';
import { getConfig } from 'store/actions/auth.action';

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch()
    React.useEffect(() => {
        dispatch(getConfig())
    }, [])
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <ToastContainer style={{ fontSize: 12, zIndex: 999999 }}
                    role='alert'
                    limit={3}
                    closeButton={true}
                    autoClose={2000}
                    position='top-right' />
                <AuthWrapper>
                    <NavigationScroll>

                        <Routes />

                    </NavigationScroll>
                </AuthWrapper>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
