import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from 'source/constants';
import { getToken } from 'AuthWrapper';

export const GET_POLICIES_LOADING = 'GET_POLICIES_LOADING';
export const GET_POLICIES = 'GET_POLICIES';

export const UPDATE_POLICY_LOADING = 'UPDATE_POLICY_LOADING';
export const UPDATE_POLICY = 'UPDATE_POLICY';

export const updatePolicy = (websiteId, policy) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: UPDATE_POLICY_LOADING
    });
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.put(`${BASE_URL}/website/edit-policy`, {
            websiteId,
            policy
        });
        if (res.data) {
            dispatch({
                type: UPDATE_POLICY,
                payload: res.data
            });
            dispatch(getPolicy(websiteId));
            return true;
        }
    } catch (error) {
        toast.error('Card having error');
        dispatch({
            type: UPDATE_POLICY_LOADING
        });
    }
};

export const getPolicy = (websiteId) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: GET_POLICIES_LOADING
    });
    const params = {
        websiteId
    };
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.get(`${BASE_URL}/website/get-policy`, { params });
        if (res.data) {
            dispatch({
                type: GET_POLICIES,
                payload: res.data.result.policy
            });
        }
    } catch (error) {
        toast.error('error in getting POLICIES');
        dispatch({
            type: GET_POLICIES_LOADING
        });
    }
};



export const updateTemplateOptions = (data) => async (dispatch) => {
    const token = await getToken();

    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.post(`${BASE_URL}/user/template/add`, { ...data });
        if (res.data) {
            toast.info(res.data.message);
            return res.data
        }
    } catch (error) {
        toast.error(error.response.data.message);
    }
};



export const getUserTemplates = (data) => async (dispatch) => {
    const token = await getToken();
    const params = {
        ...data
    }
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.get(`${BASE_URL}/user/template/list`, { params });
        if (res.data) {
            return res.data
        }
    } catch (error) {
        toast.error(error.response.data.message);
    }
};