import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from 'source/constants';
import { getToken } from 'AuthWrapper';

export const USER_WEBSITES_LOADING = 'USER_WEBSITES_LOADING';

export const GET_USER_WEBSITES = 'GET_USER_WEBSITES';

export const DELETE_USER_WEBSITES = 'DELETE_DOMAINS';

export const EDIT_USER_WEBSITES = 'EDIT_DOMAINS';

export const getWebsites = (data) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: USER_WEBSITES_LOADING
    });
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const params = {
            ...data
        };
        const res = await axios.get(`${BASE_URL}/admin/websites`, { params });
        if (res.data) {
            dispatch({
                type: GET_USER_WEBSITES,
                payload: res.data
            });
        }
        return res.data;
    } catch (error) {
        toast.error('Unable to get websites');
        dispatch({
            type: USER_WEBSITES_LOADING
        });
    }
};

export const editWebsites = (id) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: USER_WEBSITES_LOADING
    });
    try {
        axios.defaults.headers.common['Authorization'] = token;
        // const res = await axios.get(`${BASE_URL}/`);
        // if (res.data) {
        //     dispatch({
        //         type: EDIT_DOMAINS,
        //         payload: res.data
        //     });
        // }
        // return res.data;
    } catch (error) {
        toast.error('Unable to get users');
        dispatch({
            type: USER_WEBSITES_LOADING
        });
    }
};

export const deleteWebsites = (id) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: USER_WEBSITES_LOADING
    });
    try {
        axios.defaults.headers.common['Authorization'] = token;
        // const res = await axios.get(`${BASE_URL}/`);
        // if (res.data) {
        //     dispatch({
        //         type:DELETE_DOMAINS,
        //         payload: res.data
        //     });
        // }
        // return res.data;
    } catch (error) {
        toast.error('Unable to get users');
        dispatch({
            type: USER_WEBSITES_LOADING
        });
    }
};
