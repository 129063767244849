// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
        <svg width="159" height="38" viewBox="0 0 159 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 27.225H5.7585L8.0685 21.021H18.7605L21.0705 27.225H26.829L15.906 0H10.9395L0 27.225ZM9.9 16.335L13.4145 7.2105L16.9455 16.335H9.9Z" fill="#E3211F"/>
<path d="M52.1118 35.805H57.9033L70.8228 10.329H65.0148L60.5268 20.229L55.3458 10.329H49.5378L57.5733 25.113L52.1118 35.805Z" fill="#E3211F"/>
<path d="M50.729 23.1023C50.5433 22.9299 50.2968 22.8383 50.0436 22.8477H45.9751L45.938 23.0054V1.15294C45.939 1.03085 45.9156 0.909781 45.8694 0.796794C45.8231 0.683807 45.7548 0.581156 45.6684 0.494819C45.5821 0.408482 45.4794 0.340186 45.3665 0.293905C45.2535 0.247624 45.1324 0.224284 45.0103 0.225244H41.5428C41.2026 0.219544 40.868 0.313027 40.5801 0.494278L36.4879 3.75256C36.3809 3.81847 36.289 3.90627 36.2184 4.0102C36.1477 4.11414 36.0999 4.23184 36.078 4.35559C36.0561 4.47933 36.0606 4.60631 36.0913 4.72817C36.122 4.85004 36.1781 4.96404 36.2559 5.06267L37.3084 6.43464C38.3556 5.46468 39.1308 5.81617 39.2534 5.64094C39.4596 5.3482 40.0337 4.45142 40.3997 4.49472C40.7656 4.53801 40.6058 5.25955 40.6058 5.25955C40.8305 5.00083 41.1944 4.84724 41.3933 5.01216C41.7706 5.3214 40.9367 7.03454 40.9522 8.29312C40.9817 10.1588 41.2304 12.0146 41.6933 13.8222C41.9094 14.2955 42.1703 14.747 42.4726 15.1705C43.2042 15.7734 43.7863 16.5376 44.1733 17.4031C44.5251 18.4678 44.4986 19.6213 44.0981 20.6686C43.8579 21.2108 43.4982 20.8809 43.1879 19.9069C42.9704 19.2255 42.3045 19.1595 41.6634 18.9235C41.7665 19.9347 41.0975 25.7266 40.9418 27.0666C41.0887 27.1609 41.26 27.21 41.4345 27.2078H44.9443V27.213H50.0456C50.1711 27.2177 50.2963 27.1976 50.4139 27.1537C50.5316 27.1098 50.6394 27.0431 50.7311 26.9574C50.8168 26.879 50.885 26.7833 50.9312 26.6768C50.9774 26.5702 51.0005 26.455 50.9991 26.3389V23.7218C51.0004 23.6053 50.977 23.4899 50.9305 23.3831C50.8839 23.2763 50.8153 23.1806 50.729 23.1023Z" fill="#E3211F"/>
<path d="M38.1062 22.8477H33.746C33.5399 21.0325 33.4368 19.2606 33.4368 18.1947C32.5153 18.1947 31.7185 19.4317 31.5082 18.5133C31.5206 17.2516 33.0059 16.2455 33.5698 15.9085C33.7152 14.7387 33.9657 13.5844 34.3181 12.4595V1.15294C34.319 1.03085 34.2957 0.909781 34.2494 0.796794C34.2031 0.683806 34.1348 0.581155 34.0485 0.494819C33.9622 0.408482 33.8595 0.340185 33.7465 0.293904C33.6336 0.247623 33.5125 0.224283 33.3904 0.225244H29.9218C29.5813 0.21964 29.2464 0.313108 28.958 0.494278L24.8659 3.75256C24.7589 3.81847 24.667 3.90627 24.5964 4.0102C24.5258 4.11414 24.4779 4.23184 24.456 4.35559C24.4341 4.47933 24.4387 4.60631 24.4693 4.72817C24.5 4.85004 24.5561 4.96404 24.634 5.06267L26.2925 7.2273C26.3714 7.34867 26.4801 7.44782 26.6082 7.5153C26.7363 7.58278 26.8796 7.61635 27.0243 7.61281C27.234 7.62002 27.4392 7.55161 27.6026 7.42005L28.8808 6.39753V26.2915C28.8801 26.4135 28.9036 26.5344 28.95 26.6473C28.9964 26.7602 29.0647 26.8627 29.1509 26.949C29.2372 27.0353 29.3398 27.1036 29.4526 27.15C29.5655 27.1963 29.6864 27.2199 29.8084 27.2192H33.009V27.2243H38.1093C38.2349 27.229 38.3602 27.2089 38.478 27.165C38.5958 27.1211 38.7038 27.0544 38.7958 26.9687C38.8813 26.8902 38.9493 26.7945 38.9953 26.688C39.0413 26.5814 39.0643 26.4663 39.0627 26.3502V23.7218C39.0643 23.6057 39.0413 23.4906 38.9953 23.384C38.9493 23.2774 38.8813 23.1818 38.7958 23.1033C38.7033 23.0173 38.5949 22.9505 38.4765 22.9066C38.3582 22.8628 38.2323 22.8427 38.1062 22.8477Z" fill="#E3211F"/>
<path d="M71.2433 28.38H76.5893V18.117H77.8598L85.7138 28.38H92.4953L83.5853 17.5065C85.4993 16.9785 86.9898 16.005 88.0568 14.586C89.1348 13.167 89.6738 11.4345 89.6738 9.3885C89.6738 7.6505 89.2943 6.171 88.5353 4.95C87.7873 3.718 86.7258 2.7775 85.3508 2.1285C83.9868 1.4795 82.3753 1.155 80.5163 1.155H71.2433V28.38ZM76.5893 13.662V5.4285H78.8663C80.5383 5.4285 81.8253 5.786 82.7273 6.501C83.6293 7.216 84.0803 8.228 84.0803 9.537C84.0803 10.846 83.6238 11.8635 82.7108 12.5895C81.8088 13.3045 80.5273 13.662 78.8663 13.662H76.5893Z" fill="#204595"/>
<path d="M96.8472 7.9695C97.6722 7.9695 98.3762 7.6725 98.9592 7.0785C99.5422 6.4845 99.8337 5.775 99.8337 4.95C99.8337 4.125 99.5422 3.421 98.9592 2.838C98.3762 2.255 97.6722 1.9635 96.8472 1.9635C96.0112 1.9635 95.2962 2.255 94.7022 2.838C94.1192 3.421 93.8277 4.125 93.8277 4.95C93.8277 5.786 94.1192 6.501 94.7022 7.095C95.2962 7.678 96.0112 7.9695 96.8472 7.9695ZM94.3062 28.38H99.3717V11.484H94.3062V28.38Z" fill="#204595"/>
<path d="M112.373 37.455C114.606 37.455 116.448 37.081 117.9 36.333C119.363 35.596 120.452 34.5785 121.167 33.2805C121.893 31.9825 122.256 30.503 122.256 28.842V11.484H117.191V13.5465C116.52 12.7325 115.678 12.1 114.666 11.649C113.654 11.187 112.554 10.956 111.366 10.956C109.738 10.956 108.286 11.3465 107.01 12.1275C105.745 12.9085 104.744 13.9755 104.007 15.3285C103.281 16.6815 102.918 18.216 102.918 19.932C102.918 21.681 103.292 23.232 104.04 24.585C104.799 25.938 105.806 26.9995 107.06 27.7695C108.314 28.5285 109.705 28.908 111.234 28.908C112.411 28.908 113.511 28.666 114.534 28.182C115.568 27.698 116.454 27.0215 117.191 26.1525V28.5285C117.191 29.8925 116.74 30.9925 115.838 31.8285C114.947 32.6645 113.786 33.0825 112.356 33.0825C111.3 33.0825 110.321 32.8515 109.419 32.3895C108.517 31.9275 107.835 31.306 107.373 30.525L103.248 32.538C103.963 34.155 105.091 35.376 106.631 36.201C108.182 37.037 110.096 37.455 112.373 37.455ZM112.752 24.6015C111.432 24.6015 110.349 24.167 109.502 23.298C108.666 22.418 108.248 21.307 108.248 19.965C108.248 18.601 108.677 17.479 109.535 16.599C110.404 15.708 111.487 15.2625 112.785 15.2625C114.083 15.2625 115.161 15.708 116.019 16.599C116.877 17.479 117.306 18.601 117.306 19.965C117.306 21.307 116.877 22.418 116.019 23.298C115.172 24.167 114.083 24.6015 112.752 24.6015Z" fill="#204595"/>
<path d="M127.109 28.38H132.175V18.381C132.175 17.402 132.499 16.5825 133.148 15.9225C133.797 15.2515 134.683 14.916 135.805 14.916C136.905 14.916 137.768 15.257 138.395 15.939C139.033 16.61 139.352 17.5505 139.352 18.7605V28.38H144.418V17.688C144.418 16.346 144.137 15.1745 143.576 14.1735C143.015 13.1615 142.24 12.375 141.25 11.814C140.271 11.242 139.132 10.956 137.834 10.956C136.668 10.956 135.596 11.1815 134.617 11.6325C133.638 12.0725 132.824 12.65 132.175 13.365V0H127.109V28.38Z" fill="#204595"/>
<path d="M154.58 28.908C155.262 28.908 155.955 28.82 156.659 28.644C157.374 28.468 158.017 28.226 158.589 27.918L157.319 23.859C156.846 24.211 156.34 24.387 155.801 24.387C154.811 24.387 154.316 23.738 154.316 22.44V15.3285H158.028V11.484H154.316V5.148H149.25V11.484H146.99V15.3285H149.25V22.803C149.25 24.728 149.718 26.2295 150.653 27.3075C151.599 28.3745 152.908 28.908 154.58 28.908Z" fill="#204595"/>
</svg>

    );
};

export default Logo;
