import * as React from 'react';
import { useDispatch } from 'react-redux';
import { CircularProgress } from '@mui/material';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TableRow,
    IconButton,
} from '@mui/material';
import { IconTrash } from '@tabler/icons';
import FormTablePagination from '../../TableComponents/TablePagination';
import { delBuild, getBuilds } from 'store/actions/admin/adminDashboard.action';
import parseDate from 'utils/parseDate';
import { toast } from 'react-toastify';

const AllBuilds = () => {

    const [data, setData] = React.useState({
        builds: [],
        pages: 0,
        count: 0
    })

    const [page, setPage] = React.useState(1)
    const [buildsLoading, setBuildsLoading] = React.useState(true);
    const [deleteBuildLoading, setDeleteBuildLoading] = React.useState(false);

    const dispatch = useDispatch();

    const columns = [
        { id: 1, label: 'Version', width: { xl: 180, lg: 180, md: 180, sm: 200, xs: 200 } },
        { id: 2, label: 'Created At', width: 100 },
        { id: 3, label: 'Updated At', width: 100 },
        { id: 4, label: 'Actions', width: 100 },
    ];

    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    function getData(rs, pg) {
        setBuildsLoading(true)
        dispatch(getBuilds({
            limit: rs,
            page: pg
        })).then(data => {
            if (data) {
                setData({
                    builds: data.result,
                    pages: data.pages,
                    count: data.count
                })
                setBuildsLoading(false)
            }
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getData(rowsPerPage, newPage)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        getData(event.target.value, page)
    };

    React.useEffect(() => {
        getData(rowsPerPage, page);
    }, [])

    function deleteBuild(id) {
        setDeleteBuildLoading(true);
        dispatch(delBuild(id)).then(data => {
            if (data) {
                setDeleteBuildLoading(false);
                getData(rowsPerPage, page);
                toast.success(data.message);

            } else {
                setDeleteBuildLoading(false);
            }
        })
    }

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: '20px' }}>
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} align={column.align} sx={{ width: column.width }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {buildsLoading ? (
                            <>
                                <TableRow>
                                    <TableCell colSpan="4" align="center">
                                        <CircularProgress size={32} />
                                    </TableCell>
                                </TableRow>
                            </>
                        ) : (
                            <>

                                {data.builds.length > 0 ? (
                                    data.builds.map((row) => {
                                        return (
                                            <TableRow key={row.id}>
                                                <TableCell>
                                                    {row?.version}
                                                </TableCell>
                                                <TableCell>
                                                    {parseDate(row?.createdAt)}
                                                </TableCell>
                                                <TableCell>
                                                    {parseDate(row?.updatedAt)}
                                                </TableCell>
                                                <TableCell>
                                                    <div>
                                                        <IconButton disabled={deleteBuildLoading} onClick={() => deleteBuild(row.id)}>
                                                            <IconTrash color="red" stroke={1.5} size="1.2rem" />
                                                        </IconButton>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan="4" align="center">
                                            No Template Found
                                        </TableCell>
                                    </TableRow>
                                )}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {!buildsLoading ? (
                <FormTablePagination
                    component="div"
                    rowsPerPageOptions={[5, 10, 25, 100]}
                    count={data.pages}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            ) : (
                <></>
            )}
        </Paper>
    );
};

export default AllBuilds;
