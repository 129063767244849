import { Button, CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { addTemplateBuild } from "store/actions/admin/adminDashboard.action";
import getBase64 from "utils/convertBase64";
import AllBuilds from "./AllBuilds";

export default function PrivacyTemplate() {
  const dispatch = useDispatch();
  const [uploadLoading, setUploadLoading] = React.useState(false);
  const [builds, setBuilds] = React.useState({
    privacyName: "",
    ccpFormName: "",
    ccpaTemplateFile: "",
    widgetTemplateFile: "",
    privacyTemplateFile: "",
    widgetName: "",
  });

  function handleUploadPrivacyBuild(e) {
    getBase64(e.target.files[0]).then((data) => {
      setBuilds({
        ...builds,
        privacyName: e.target.files[0].name,
        privacyTemplateFile: data,
      });
    });
  }

  function handleUploadCcpaBuild(e) {
    getBase64(e.target.files[0]).then((data) => {
      setBuilds({
        ...builds,
        ccpFormName: e.target.files[0].name,
        ccpaTemplateFile: data,
      });
    });
  }

  function handleUploadWidgetBuild(e) {
    getBase64(e.target.files[0]).then((data) => {
      setBuilds({
        ...builds,
        widgetTemplateFile: data,
        widgetName: e.target.files[0].name,
      });
    });
  }

  function uploadBuilds() {
    setUploadLoading(true);
    dispatch(
      addTemplateBuild({
        widgetTemplateFile: builds.widgetTemplateFile,
        privacyTemplateFile: builds.privacyTemplateFile,
        ccpaTemplateFile: builds.ccpaTemplateFile,
      })
    )
      .then((response) => {
        if (response) {
          setUploadLoading(false);
        } else {
          setUploadLoading(false);
        }
      })
      .catch((er) => {
        toast.erro("Error ocurred!");
        setUploadLoading(false);
      });
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        mb={1}
      >
        <Box display="flex" alignItems="center" mb={2}>

             {!builds.ccpFormName ? (
            <Button
              sx={{ mr: 2 }}
              variant="contained"
              color="primary"
              htmlFor="upload-ccpa-form-build"
              component="label"
              disabled={uploadLoading}
            >
              Upload CCPA Build
              <input
                id="upload-ccpa-form-build"
                onChange={handleUploadCcpaBuild}
                type="file"
                hidden
              />
            </Button>
          ) : (
            <Typography>
              {builds.ccpFormName}{" "}
              <Button
                color="error"
                onClick={() =>
                  setBuilds({
                    ...builds,
                    ccpaTemplateFile: "",
                    ccpFormName: "",
                  })
                }
              >
                Remove
              </Button>
            </Typography>
          )}

          {!builds.privacyName ? (
            <Button
              variant="contained"
              color="primary"
              htmlFor="upload-privacy-build"
              component="label"
              disabled={uploadLoading}
              sx={{ mr: 2 }}
            >
              Upload Privacy Build
              <input
                id="upload-privacy-build"
                onChange={handleUploadPrivacyBuild}
                type="file"
                hidden
              />
            </Button>
          ) : (
            <Typography>
              {builds.privacyName}{" "}
              <Button
                color="error"
                onClick={() =>
                  setBuilds({
                    ...builds,
                    privacyName: "",
                    privacyTemplateFile: "",
                  })
                }
              >
                Remove
              </Button>
            </Typography>
          )}

          {!builds.widgetName ? (
            <Button
              variant="contained"
              color="primary"
              htmlFor="upload-widget-build"
              component="label"
              disabled={uploadLoading}
            >
              Upload Widget Build
              <input
                id="upload-widget-build"
                onChange={handleUploadWidgetBuild}
                type="file"
                hidden
              />
            </Button>
          ) : (
            <Typography>
              {builds.widgetName}{" "}
              <Button
                color="error"
                onClick={() =>
                  setBuilds({
                    ...builds,
                    widgetTemplateFile: "",
                    widgetName: "",
                  })
                }
              >
                Remove
              </Button>
            </Typography>
          )}

         
        </Box>
        <Button
          color="primary"
          variant="contained"
          disabled={uploadLoading || !builds.widgetName || !builds.privacyName || !builds.ccpFormName}
          onClick={() => uploadBuilds()}
        >
          Upload Builds
        </Button>
        {uploadLoading && (
          <Typography variant="subtitle1" sx={{ mt: 0.5, fontSize: 16 }}>
            Build upload is in progress
          </Typography>
        )}
      </Box>
      <AllBuilds />
    </>
  );
}
