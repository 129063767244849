import * as Actions from '../../actions/admin/adminDashboard.action';

const initialState = {
    adminDashboardLoading: false,
    adminAnalytics: {}
};

const adminDashboardAnalytics = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case Actions.DASHBOARD_ANALYTICS_LOADING:
            return { ...state, adminDashboardLoading: !state.adminDashboardLoading };
        case Actions.GET_DASHBOARD_ANALYTICS:
            return { ...state, adminAnalytics: payload, adminDashboardLoading: false };

        default:
            return state;
    }
};

export default adminDashboardAnalytics;
