// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { menuItems, adminMenu } from 'menu-items';
import { useState, useEffect } from 'react';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const [menu, setMenu] = useState({ items: [] });

    useEffect(() => {
        if (localStorage.getItem('role') === 'admin') {
            setMenu({ ...menu, items: [...adminMenu.items] });
        } else {
            setMenu({ ...menu, items: [...menuItems.items] });
        }
    }, [localStorage.getItem('role')]);

    const navItems = menu.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
