import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getUser } from 'store/actions/auth.action';
import { getSidebarWebsites } from 'store/actions/sidebar.actions';
import SplashScreen from 'ui-component/SplashScreen';

const auth = getAuth();

export const getToken = async () => {
    const token = await auth.currentUser.getIdToken();
    if (token) {
        return `Bearer ${token}`;
    } else {
        return null;
    }
};

const AuthWrapper = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { websitesLoading } = useSelector((state) => state.sidebar);

    const [loading, setLoading] = React.useState(true);

    const state = useSelector((state) => state.auth);
    const { regLoading, userLoading, user,registerLoading, addUserLoading } = useSelector((state) => state.auth);

    const role = localStorage.getItem('role');

    React.useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (!user) {
                setLoading(false);
                navigate('/login');
            } else {
                if (user && !userLoading && !state.registerLoading) {
                    if (user.displayName)
                        dispatch(getUser({ name: user.displayName })).then((data) => {
                            if (data) {
                                if (
                                    window.location.pathname === '/' ||
                                    window.location.pathname === '/login' ||
                                    window.location.pathname === '/register'
                                ) {
                                    navigate(`/${data.role}`);
                                    // navigate('/user/dashboard');
                                } else navigate(window.location.pathname);
                                setLoading(false);
                            } else setLoading(false);
                        });
                }
            }
        });
    }, []);

    React.useEffect(() => {
        if (user) {
            dispatch(getSidebarWebsites());
        }
    }, [user]);

    return (
        <div>
            {!loading && !userLoading && !websitesLoading ? (
                { ...children }
            ) : (
                <>
                    <SplashScreen />
                </>
            )}
        </div>
    );
};

export default AuthWrapper;
