import * as Actions from '../actions/billing.actions';

const initialState = {
    addCardLoading: false,
    cardsLoading: false,
    delCardLoading: false,
    createSubscriptionLoading: false,
    cancleSubscriptionLoading: false,
    invoicesLoading: false,
    invoices: [],
    cards: [],
    pages: 0,
    limit: 0,
    count: 0,
    page: 0
};

const billing = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case Actions.ADD_CARD_LOADING:
            return {
                ...state,
                addCardLoading: !state.addCardLoading
            };
        case Actions.GET_CARDS_LOADING:
            return {
                ...state,
                cardsLoading: !state.cardsLoading
            };
        case Actions.GET_CARDS:
            return {
                ...state,
                cardsLoading: false,
                cards: payload.result,
                pages: payload.pages,
                limit: payload.limit,
                count: payload.count,
                page: payload.page
            };
        case Actions.CREATE_SUBSCRIPTION_LOADING:
            return {
                ...state,
                createSubscriptionLoading: !state.createSubscriptionLoading
            };
        case Actions.CANCEL_SUBSCRIPTION_LOADING:
            return {
                ...state,
                cancleSubscriptionLoading: !state.cancleSubscriptionLoading
            };
        case Actions.DELETE_CARDS_LOADING:
            return {
                ...state,
                delCardLoading: !state.delCardLoading
            };
        case Actions.DELETE_CARDS:
            let temp = state.cards;
            temp = temp.filter((temp) => temp.id !== payload);
            return {
                ...state,
                cards: temp,
                delCardLoading: false
            };
        case Actions.GET_INVOICE_LOADING:
            return {
                ...state,
                invoicesLoading: !state.invoicesLoading
            };
        case Actions.GET_INVOICE:
            return {
                ...state,
                invoices: payload,
                invoicesLoading: false
            };
        default:
            return state;
    }
};

export default billing;
