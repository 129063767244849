import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from 'source/constants';
import { getToken } from 'AuthWrapper';

export const SUBSCRIPTIONS_LOADING = 'SUBSCRIPTIONS_LOADING';

export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';

export const DELETE_SUBSCRIPTIONS = 'DELETE_SUBSCRIPTIONS';

export const EDIT_SUBSCRIPTIONS = 'EDIT_SUBSCRIPTIONS';

export const getSubscriptions = (data) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: SUBSCRIPTIONS_LOADING
    });
    try {
        const params = {
            ...data
        };
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.get(`${BASE_URL}/admin/subscription`, { params });
        if (res.data) {
            dispatch({
                type: GET_SUBSCRIPTIONS,
                payload: res.data
            });
        }
        return res.data;
    } catch (error) {
        toast.error('Unable to get subscriptions');
        dispatch({
            type: SUBSCRIPTIONS_LOADING
        });
    }
};

export const editSubscription = (id) => async (dispatch) => {
    const token = await getToken(id);
    dispatch({
        type: SUBSCRIPTIONS_LOADING
    });
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.put(`${BASE_URL}/user/subscription?limit=10&page=1`, { id });
        if (res.data) {
            dispatch({
                type: EDIT_SUBSCRIPTIONS,
                payload: res.data
            });
        }
        return res.data;
    } catch (error) {
        toast.error('Unable to get users');
        dispatch({
            type: SUBSCRIPTIONS_LOADING
        });
    }
};

export const deleteSubscription = (id) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: SUBSCRIPTIONS_LOADING
    });
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.post(`${BASE_URL}/user/subscription?limit=10&page=1`, { id });
        if (res.data) {
            dispatch({
                type: DELETE_SUBSCRIPTIONS,
                payload: res.data
            });
        }
        return res.data;
    } catch (error) {
        toast.error('Unable to get users');
        dispatch({
            type: SUBSCRIPTIONS_LOADING
        });
    }
};
