import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from 'source/constants';
import { getToken } from 'AuthWrapper';

export const DASHBOARD_ANALYTICS_LOADING = 'DASHBOARD_ANALYTICS_LOADING';

export const GET_DASHBOARD_ANALYTICS = 'GET_DASHBOARD_ANALYTICS';

export const getDashboardAnalytics = () => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: DASHBOARD_ANALYTICS_LOADING
    });
    try {
        axios.defaults.headers.common['Authorization'] = token;

        const res = await axios.get(`${BASE_URL}/admin/analytics`);
        if (res.data) {
            dispatch({
                type: GET_DASHBOARD_ANALYTICS,
                payload: res.data.result
            });
        }
        return res.data;
    } catch (error) {
        toast.error('Unable to get dashboard analytics');
        dispatch({
            type: DASHBOARD_ANALYTICS_LOADING
        });
    }
};



export const addTemplateBuild = (data) => async (dispatch) => {
    const token = await getToken();
    try {
        axios.defaults.headers.common['Authorization'] = token;

        const axInst = axios.create({
            baseURL: BASE_URL,
            timeout: 999999
        })
        const res = await axInst.post('/admin/template/add', { ...data });
        if (res.data) {
            dispatch(getBuilds({ page: 1, limit: 5 }));
            return res.data
        }
        return res.data;
    } catch (error) {
        toast.error(error.response.data.message);
    }
};


export const getBuilds = (data) => async (dispatch) => {
    const token = await getToken();
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const params = {
            ...data
        }
        const res = await axios.get(`${BASE_URL}/admin/template/list`, { params });
        if (res.data) {
            return res.data
        }
        return res.data;
    } catch (error) {
        toast.error(error.response.data.message);
    }
};

export const delBuild = (templateId) => async (dispatch) => {
    const token = await getToken();

    try {
        const params = {
            templateId
        };
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.delete(`${BASE_URL}/admin/template/remove`, { params });
        if (res.data) {

            toast.info(res.data.message);
            return res.data
        }
    } catch (error) {
        toast.error(error.response.data.message);
    }
};