import {
  signOut,
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
} from "firebase/auth";

import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "source/constants";
import { getToken } from "AuthWrapper";

const auth = getAuth();
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_LOADING = "LOGIN_USER_LOADING";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_LOADING = "REGISTER_USER_LOADING";

export const FORGOT_PASSWORD_LAODING = "FORGOT_PASSWORD_LAODING";

export const GET_USER_LOADING = "GET_USER_LOADING";
export const GET_USER = "GET_USER";
export const ADD_USER = "ADD_USER";
export const ADD_USER_LOADING = "ADD_USER_LOADING";
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_LOADING = "UPDATE_USER_LOADING";
export const LOGOUT_USER = "LOGOUT_USER";
export const GET_CONFIG = "GET_CONFIG";
export const UPDATE_USER_PROFILE = "UPDATE_USER__PROFILE";
export const UPDATE_USER_PROFILE_LOADING = "UPDATE_USER_PROFILE__LOADING";

export const registerUser = (credentials) => async (dispatch) => {
  try {
    dispatch({
      type: REGISTER_USER_LOADING,
    });
    let data = null;
    data = createUserWithEmailAndPassword(
      auth,
      credentials.email,
      credentials.password
    ).then(async (res) => {
      if (res.user) {
        const response = await updateProfile(auth.currentUser, {
          displayName: credentials.firstName + " " + credentials.lastName,
        });
        data = await dispatch(
          addUser({ name: credentials.firstName + " " + credentials.lastName })
        );
        dispatch(getConfig());
        return data;
      } else return null;
    });
    return data;
  } catch (error) {
    toast.error("Error is register account");
    dispatch({
      type: REGISTER_USER_LOADING,
    });
    if (error.code === "auth/email-already-in-use") {
      toast.error("Email already exist");
    }
  }
};

export const LoginUser = (credentials) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_USER_LOADING,
    });
    const res = await signInWithEmailAndPassword(
      auth,
      credentials.email,
      credentials.password
    );
    if (res.data) {
      const user = dispatch(getUser({ name: auth.currentUser.displayName }));
      dispatch(getConfig());

      dispatch({
        type: LOGIN_USER_LOADING,
      });

      return res.user;
    }
  } catch (error) {
    dispatch({
      type: LOGIN_USER_LOADING,
    });

    if (error.code === "auth/user-not-found") { toast.error("User not found") }
    else if (error.code === "auth/wrong-password")
      toast.error("Enter correct Password!");
    else if (error.code === "auth/network-request-failed")
      toast.error("Check your internet connection!");
  }
};

export const forgetPassword = (data) => async (dispatch) => {
  try {
    dispatch({
      type: FORGOT_PASSWORD_LAODING,
    });
    const res = await sendPasswordResetEmail(auth, data.email);
    dispatch({
      type: FORGOT_PASSWORD_LAODING,
    });
    toast.info("Password reset email sent at given email");
    return "email send";
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_LAODING,
    });


    if (error.code === "auth/user-not-found")
      toast.error("Enter valid email address!");
  }
};

export const addUser = (data) => async (dispatch) => {
  const token = await getToken();
  dispatch({
    type: ADD_USER_LOADING,
  });
  try {
    axios.defaults.headers.common["Authorization"] = token;
    const res = await axios.post(`${BASE_URL}/user`, { ...data });
    if (res.data) {
      dispatch(getUser(data)).then((data) => {
        if (data) {
          return res.data;
        }
      });
      dispatch({
        type: ADD_USER_LOADING,
      });
      dispatch({
        type: REGISTER_USER_LOADING,
      });
      return res.data;
    }
  } catch (error) {
    toast.error("Error while adding user ");
    dispatch({
      type: ADD_USER_LOADING,
    });
    return null;
  }
};

export const getUser = (data) => async (dispatch) => {
  const token = await getToken();
  dispatch({
    type: GET_USER_LOADING,
  });
  try {

    axios.defaults.headers.common["Authorization"] = token;
    const res = await axios.get(`${BASE_URL}/user`, { ...data });
    if (res.data) {
      localStorage.setItem("role", res.data.user.role);
      dispatch({
        type: GET_USER,
        payload: res.data.user,
      });
      return res.data.user;
    }
  } catch (error) {

    dispatch({
      type: GET_USER_LOADING,
    });
    return null;
  }
};

export const getUserWithoutLoading = (data) => async (dispatch) => {
  const token = await getToken();

  try {
    axios.defaults.headers.common["Authorization"] = token;
    const res = await axios.get(`${BASE_URL}/user`, { ...data });
    if (res.data) {
      localStorage.setItem("role", res.data.user.role);
      dispatch({
        type: GET_USER,
        payload: res.data.user,
      });
      return res.data.user;
    }
  } catch (error) {
    return null;
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    signOut(auth).then((data) => {
      dispatch({
        type: LOGOUT_USER,
      });
    });
  } catch (error) {

  }
};

export const getConfig = () => async (dispatch) => {
  const token = await getToken();
  try {
    axios.defaults.headers.common["Authorization"] = token;
    const res = await axios.get(`${BASE_URL}/config`);
    if (res.data) {
      console.log("CONFIG: ", res.data.config);
      localStorage.setItem("config", JSON.stringify(res.data.config));
      dispatch({
        type: GET_CONFIG,
        payload: res.data,
      });
      return res.data;
    }
  } catch (error) {
    return null;
  }
};

export const updateProfileInfo = (data) => async (dispatch) => {
  const token = await getToken();
  try {
    dispatch({
      type: UPDATE_USER_PROFILE_LOADING,
    });
    axios.defaults.headers.common["Authorization"] = token;
    const res = await axios.post(`${BASE_URL}/user/update-profile`, {
      ...data,
    });
    if (res.data) {
      dispatch({
        type: UPDATE_USER_PROFILE,
        payload: res.data.user,
      });
      toast.info("User Updated successfully!");
      return res.data;
    }
  } catch (error) {
    dispatch({
      type: UPDATE_USER_PROFILE_LOADING,
    });
    return null;
  }
};
