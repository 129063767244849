import * as Actions from '../../actions/admin/websites.action';

const initialState = {
    websiteLoading: false,
    websites: [],
    count: 0,
    pages: 0,
    limit: 0,
    page: 0
};

const adminWebsites = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case Actions.USER_WEBSITES_LOADING:
            return { ...state, websiteLoading: !state.websiteLoading };
        case Actions.GET_USER_WEBSITES:
            return {
                ...state,
                websites: payload.result,
                count: payload.count,
                pages: payload.pages,
                limit: payload.limit,
                page: payload.page,
                websiteLoading: false
            };

        case Actions.EDIT_USER_WEBSITES:
        case Actions.DELETE_USER_WEBSITES:
        default:
            return state;
    }
};

export default adminWebsites;
