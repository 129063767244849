import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router';
import PrivacyTemplate from 'views/Admin/privacy-template';
import TemplateOptions from 'views/users/TemplateOptions';
import FreeUsers from 'views/Admin/Freeusers';

// LOgin routes
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const AuthForgetPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/forgotPassword')));
const CCPA = Loadable(lazy(() => import('views/pages/CCPARequest/Index')));

// User routes
// dashboard routing
const UserWebsites = Loadable(lazy(() => import('views/users/domain/view-websites/Index')));
const AddWebsites = Loadable(lazy(() => import('views/users/domain/add-wesbite/Index')));
const UserInvoices = Loadable(lazy(() => import('views/users/billing/invoices/Index')));
const UserDashboard = Loadable(lazy(() => import('views/users/dashboard/Index')));

const Billing = Loadable(lazy(() => import('views/users/billing/Index')));
const Setting = Loadable(lazy(() => import('views/users/settings/Index')));
const Template = Loadable(lazy(() => import('views/users/template/Index')));
const CCPARequests = Loadable(lazy(() => import('views/users/ccpa-requests')));

// Admin Routes
const AdminDashboardDefault = Loadable(lazy(() => import('views/Admin/dashboard/dashboard')));

// sample page routing
const AdminSamplePage = Loadable(lazy(() => import('views/Admin/pages/Samplepage')));

//Admin Pages
const UserTable = Loadable(lazy(() => import('views/Admin/users')));
const Websites = Loadable(lazy(() => import('views/Admin/websites')));
const Invoices = Loadable(lazy(() => import('views/Admin/invoices')));
const Subscriptions = Loadable(lazy(() => import('views/Admin/subscription')));

// ==============================|| MAIN ROUTING ||============================== //

const role = localStorage.getItem('role');

const routes = (auth) => [
    {
        path: 'user',
        element: <MainLayout />,
        children: [
            {
                path: 'dashboard',
                element: <UserDashboard />
            },
            {
                path: 'websites',
                element: <UserWebsites />
            },
            {
                path: 'add/website',
                element: <AddWebsites />
            },
            {
                path: 'billing',
                element: <Billing />
            },
            {
                path: 'invoices',
                element: <UserInvoices />
            },
            {
                path: 'setting',
                element: <Setting />
            },
            // {
            //     path: 'template',
            //     element: <Template />
            // },

            {
                path: 'template-configs',
                element: <TemplateOptions />
            },
            {
                path: 'ccpa-requests',
                element: <CCPARequests />
            },
            {
                path: '/user',
                element: <Navigate to="/user/dashboard" />
            }
        ]
    },
    {
        path: 'admin',
        element: <MainLayout />,
        children: [
            {
                path: 'dashboard',
                element: <AdminDashboardDefault />
            },
            {
                path: 'sample-page',
                element: <AdminSamplePage />
            },
            {
                path: 'template-builds',
                element: <PrivacyTemplate />
            },
            {
                path: 'free-users',
                element: <FreeUsers />
            },
            {
                path: '/admin',
                element: <Navigate to="/admin/dashboard" />
            },

            {
                path: 'users',
                element: <UserTable />
            },
            { path: 'websites', element: <Websites /> },
            { path: 'subscriptions', element: <Subscriptions /> },
            { path: 'invoices', element: <Invoices /> }
        ]
    },
    {
        path: 'request',
        element: <MinimalLayout />,
        children: [
            {
                path: 'ccpa-request',
                element: <CCPA />
            }
        ]
    },
    {
        path: '/',
        element: <MinimalLayout />,
        children: [
            {
                path: '/login',
                element: <AuthLogin />
            },
            {
                path: '/register',
                element: <AuthRegister />
            },
            {
                path: '/forget-password',
                element: <AuthForgetPassword />
            },

            {
                path: '/',
                element: role && role === 'admin' ? <Navigate to="/admin" /> : role && role === 'user' && <Navigate to="/user" />
            }
            // {
            //     path: '/',
            //     element: <Navigate to="/request/ccpa-request" />
            // }
        ]
    }
];

export default routes;
