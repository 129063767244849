import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from 'source/constants';
import { getToken } from 'AuthWrapper';

export const GET_CCPA_REQUEST = 'GET_CCPA_REQUEST';
export const GET_CCPA_REQUEST_LOADING = 'GET_CCPA_REQUEST_LOADING';
export const DELETE_CCPA_REQUEST_LOADING = 'DELETE_CCPA_REQUEST_LOADING';
export const DELETE_CCPA_REQUEST = 'DELETE_CCPA_REQUEST';

export const getCCPARequest = (data) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: GET_CCPA_REQUEST_LOADING
    });
    try {
        const params = {
            ...data
        };
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.get(`${BASE_URL}/ccpa-requests/list`, { params });
        if (res.data) {
            dispatch({
                type: GET_CCPA_REQUEST,
                payload: res.data
            });

            return res.data;
        }
    } catch (error) {
        toast.error('error in getting requests');
        dispatch({
            type: GET_CCPA_REQUEST_LOADING
        });
    }
};
export const delCCPARequest = (requestId) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: DELETE_CCPA_REQUEST_LOADING
    });
    try {
        const params = {
            requestId
        };
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.delete(`${BASE_URL}/ccpa-requests/remove`, { params });
        if (res) {
            dispatch({
                type: DELETE_CCPA_REQUEST,
                payload: requestId
            });
            toast.info('CCPA request remove successfully!');
        }
    } catch (error) {
        console.log('ERROE IN VARD DEL:', error);
        toast.error('error in del ccpa request');
        dispatch({
            type: DELETE_CCPA_REQUEST_LOADING
        });
    }
};
