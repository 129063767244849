import { Box } from "@mui/system";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBuilds } from "store/actions/admin/adminDashboard.action";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Button,
  Card,
  CircularProgress,
  FormHelperText,
  Grid,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { updateTemplateOptions } from "store/actions/template.actions";
import Templates from "./Templates";

export default function TemplateOptions() {
  const { websiteId } = useSelector((state) => state.sidebar);

  const dispatch = useDispatch();
  const [templatesLoading, setTemplatesLoading] = React.useState(true);
  const [selectedTemplate, setSelectedTemplate] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);
  const [errors, setErrors] = React.useState({
    email: "",
    phone: "",
    companyName: "",
    ccpaFormLink: "",
    privacyLink: "",
  });

  const [selectedTemplatePrivacyTheme, setSelectedTemplatePrivacyTheme] =
    React.useState("");
  const [selectedTemplateWidgetTheme, setSelectedTemplateWidgetTheme] =
    React.useState("");

  const [templates, setTemplates] = React.useState([]);

  const [contacts, setContacts] = React.useState({
    email: "",
    phone: "",
    companyName: "",
  });
  const [links, setLinks] = React.useState({
    ccpaFormLink: "",
    privacyLink: "",
  });

  const [templateLanguages, setTemaplteLangauges] = React.useState([]);

  React.useEffect(() => {
    dispatch(getBuilds({ page: 1, limit: 200 })).then((data) => {
      if (data.result.length > 0) {

        setTemplates(data.result);
        setSelectedTemplate(data.result[0]);
        setSelectedTemplatePrivacyTheme(
          data?.result[0]?.privacyTemplateOptions?.themes[0]
        );
        setSelectedTemplateWidgetTheme(
          data?.result[0]?.widgetTemplateOptions?.themes[0]
        );
        setTemaplteLangauges([
          data?.result[0]?.privacyTemplateOptions.avaialbleLanguages[0],
        ]);
        setTemplatesLoading(false);
      } else {
        setTemplatesLoading(false);
      }
    });
  }, []);

  React.useEffect(() => {
    if (
      links.ccpaFormLink &&
      links.privacyLink &&
      contacts.email &&
      contacts.phone &&
      contacts.companyName
    ) {
      setDisabled(false);
    } else setDisabled(true);

    if (!links.ccpaFormLink) {
      setErrors({
        ...errors,
        ccpaFormLink: "Enter valid CCPA form link",
      });
    }
    if (!links.privacyLink) {
      setErrors({
        ...errors,
        privacyLink: "Enter valid privacy link",
      });
    }
    if (!contacts.email) {
      setErrors({
        ...errors,
        email: "Enter valid email address",
      });
    }
    if (!contacts.phone) {
      setErrors({
        ...errors,
        phone: "Enter valid phone number",
      });
    }
    if (!contacts.companyName) {
      setErrors({
        ...errors,
        companyName: "Enter compnay name",
      });
    }
  }, [links, contacts]);

  function handleChangeTempate(tplte) {
    setSelectedTemplate(tplte);
  }

  function handleChangePrivacyTheme(e) {
    setSelectedTemplatePrivacyTheme(e.target.value);
  }

  function handleChangeWidgetTheme(e) {
    setSelectedTemplateWidgetTheme(e.target.value);
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function handleLanguages(e) {
    const {
      target: { value },
    } = e;
    setTemaplteLangauges(value);
  }

  function handleSubmit() {
    setLoading(true);
    dispatch(
      updateTemplateOptions({
        templateid: selectedTemplate.id,
        websiteId: websiteId,
        config: {
          privacy: {
            ...selectedTemplate.privacyTemplateConfig,
            languages: templateLanguages,
            links: {
              ccpaFormLink: links.ccpaFormLink,
              privacyLink: links.privacyLink,
            },
          },
          widget: {
            ...selectedTemplate.widgetTemplateConfig,
            languages: templateLanguages,
            links: {
              ccpa: links.ccpaFormLink,
              privacy: links.privacyLink,
            },
            contact: {
              ...contacts,
            },
          },
        },
      })
    )
      .then((data) => {
        if (data) {
          setContacts({
            email: "",
            phone: "",
            companyName: "",
          });
          setLinks({
            ccpaFormLink: "",
            privacyLink: "",
          });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function handleLinks(e) {
    setLinks({
      ...links,
      [e.target.name]: e.target.value,
    });
  }

  function handleContacts(e) {
    setContacts({
      ...contacts,
      [e.target.name]: e.target.value,
    });
  }

  return (
    <>
      {templatesLoading ? (
        <Box
          height="60vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {templates.length !== 0 &&
            <Box>
              <FormControl sx={{ mb: 3, width: "100%", maxWidth: 300 }}>
                <InputLabel id="select-tempalte-version">
                  Template Version
                </InputLabel>
                <Select
                  labelId="select-tempalte-version"
                  id="demo-simple-select"
                  value={selectedTemplate}
                  label="Template Version"
                  onChange={handleChangeTempate}
                >
                  {templates.map((data) => {
                    return <MenuItem value={data}>{data.version}</MenuItem>;
                  })}
                </Select>
              </FormControl>

              {selectedTemplate && (
                <Grid container spacing={4}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="h4" sx={{ marginBottom: 3 }}>
                      Privacy Template
                    </Typography>
                    {selectedTemplatePrivacyTheme && (
                      <FormControl sx={{ mb: 3, width: "100%", maxWidth: 300 }}>
                        <InputLabel id="select-tempalte-version">
                          Select Privacy Template Theme
                        </InputLabel>
                        <Select
                          labelId="select-tempalte-version"
                          id="demo-simple-select"
                          value={selectedTemplatePrivacyTheme}
                          label="Template Version"
                          onChange={handleChangePrivacyTheme}
                        >
                          {selectedTemplate?.privacyTemplateOptions?.themes.map(
                            (theme) => {
                              return (
                                <MenuItem value={theme}>{theme.name}</MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                  {selectedTemplateWidgetTheme && <Grid item xs={12} md={6}>
                    <Typography variant="h4" sx={{ marginBottom: 3 }}>
                      Widget Template
                    </Typography>

                    {selectedTemplateWidgetTheme && (
                      <FormControl sx={{ mb: 3, width: "100%", maxWidth: 300 }}>
                        <InputLabel id="select-tempalte-version">
                          Select Widget Template Theme
                        </InputLabel>
                        <Select
                          labelId="select-tempalte-version"
                          id="demo-simple-select"
                          value={selectedTemplateWidgetTheme}
                          label="Template Version"
                          onChange={handleChangeWidgetTheme}
                        >
                          {selectedTemplate?.privacyTemplateConfig?.themes.map(
                            (theme) => {
                              return (
                                <MenuItem value={theme}>{theme.name}</MenuItem>
                              );
                            }
                          )}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>}
                </Grid>
              )}

              <Box display="flex" alignItems="center" justifyContent="center">
                <FormControl sx={{ m: 1, width: 300 }}>
                  <InputLabel id="demo-multiple-name-label">
                    Select Languages
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={templateLanguages}
                    onChange={handleLanguages}
                    input={<OutlinedInput label="Name" />}
                    MenuProps={MenuProps}
                  >
                    {selectedTemplate.privacyTemplateOptions.avaialbleLanguages.map(
                      (language) => (
                        <MenuItem key={language} value={language}>
                          {language.name}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Box>
              <Box display="flex" justifyContent="center">
                <Grid container spacing={0.5}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      fullWidth
                      label="CCPA Form Link"
                      sx={{ m: 2 }}
                      variant="outlined"
                      name="ccpaFormLink"
                      value={links.ccpaFormLink}
                      onChange={handleLinks}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Privacy Information Link"
                      sx={{ m: 2 }}
                      variant="outlined"
                      name="privacyLink"
                      value={links.privacyLink}
                      onChange={handleLinks}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Company Name"
                      sx={{ m: 2 }}
                      variant="outlined"
                      name="companyName"
                      value={contacts.compnayName}
                      onChange={handleContacts}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Email"
                      sx={{ m: 2 }}
                      variant="outlined"
                      name="email"
                      type="email"
                      value={contacts.email}
                      onChange={handleContacts}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <TextField
                      fullWidth
                      label="Phone Number"
                      sx={{ m: 2 }}
                      variant="outlined"
                      name="phone"
                      value={contacts.phone}
                      onChange={handleContacts}
                    />
                  </Grid>
                </Grid>
                {/* <div> */}
                {/* <TextField
                label="CCPA Form Link"
                sx={{ m: 2 }}
                variant="outlined"
                name="ccpaFormLink"
                value={links.ccpaFormLink}
                onChange={handleLinks}
              /> */}
                {/* {errors.ccpaFormLink && <FormHelperText sx={{ color: 'red', marginTop:"5px" }}>{errors.ccpaFormLink}</FormHelperText>} */}
                {/* </div> */}
                {/* <div>
              <TextField
                label="Privacy Information Link"
                sx={{ m: 2 }}
                variant="outlined"
                name="privacyLink"
                value={links.privacyLink}
                onChange={handleLinks}
              />
              {/* {errors.privacyLink && <FormHelperText sx={{ color: 'red', marginTop:"5px" }}>{errors.privacyLink}</FormHelperText>} */}
                {/* </div> */}
              </Box>
              {/* <Box my={2} display="flex" justifyContent="center">
            <div>
              <TextField
                label="Company Name"
                sx={{ m: 2 }}
                variant="outlined"
                name="companyName"
                value={contacts.compnayName}
                onChange={handleContacts}
              />
              
            </div>
            <div>
              <TextField
                label="Email"
                sx={{ m: 2 }}
                variant="outlined"
                name="email"
                type="email"
                value={contacts.email}
                onChange={handleContacts}
              />
             
            </div>
            <div>
              <TextField
                label="Phone Number"
                sx={{ m: 2 }}
                variant="outlined"
                name="phone"
                value={contacts.phone}
                onChange={handleContacts}
              />
             
            </div>
          </Box> */}

              <Box display="flex" justifyContent="center" mt={3}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading || disabled}
                  onClick={() => handleSubmit()}
                >
                  Add Template
                </Button>
              </Box>
            </Box>
          }
        </>
      )}
      <Templates />
    </>
  );
}
