import * as Actions from '../actions/website.actions';

const initialState = {
    addWebsiteLoading: false,
    getWebsitesLoading: false,
    getanalyticsLoading: false,

    websites: [],
    analytics: null
};

const websites = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case Actions.ADD_WEBSITE_LOADING:
            return {
                ...state,
                addWebsiteLoading: !state.addWebsiteLoading
            };
        case Actions.GET_WEBSITES_LOADING:
            return {
                ...state,
                getWebsitesLoading: !state.getWebsitesLoading
            };
        case Actions.GET_WEBSITES:
            return {
                ...state,
                websites: payload,
                getWebsitesLoading: false
            };
        case Actions.ADD_WEBSITE:
            return {
                ...state,
                addWebsiteLoading: false
            };
        case Actions.GET_ANALYTICS_LOADING:
            return {
                ...state,
                getanalyticsLoading: !state.getanalyticsLoading
            };
        case Actions.GET_ANALYTICS:
            return {
                ...state,
                getanalyticsLoading: false,
                analytics: payload
            };
        default:
            return state;
    }
};

export default websites;
