import * as Actions from '../../actions/admin/invoices.action';

const initialState = {
    invoicesLoading: false,
    userInvoices: [
        // {
        //     id: 1,
        //     userName: 'John Smith',
        //     email: 'test101@gmail.com',
        //     invoice: 'SOS'
        // },
        // {
        //     id: 2,
        //     userName: 'Jake Peterson',
        //     email: 'test101@gmail.com',
        //     invoice: 'SOS'
        // },
        // {
        //     id: 3,
        //     userName: 'Nate Jackson',
        //     email: 'test101@gmail.com',
        //     invoice: 'SOS'
        // }
    ],
    count: 0,
    pages: 0,
    limit: 0,
    page: 0
};

const invoices = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case Actions.INVOICES_LOADING:
            return { ...state, invoicesLoading: !state.invoicesLoading };
        case Actions.GET_INVOICES:
            return {
                ...state,
                userInvoices: payload.result,
                invoicesLoading: !state.invoicesLoading,
                pages: payload.pages,
                limit: payload.limit,
                page: payload.page
            };
        case Actions.EDIT_INVOICES:
        case Actions.DELETE_INVOICES:
        default:
            return state;
    }
};

export default invoices;
