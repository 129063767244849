import * as Actions from '../actions/auth.action';

const initialState = {
    user: null,
    loginLoading: false,
    userLoading: false,
    addUserLoading: false,
    updateUserProfileLoading: false,
    registerLoading: false,
    forgetPassLoading: false
};

const auth = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case Actions.FORGOT_PASSWORD_LAODING:
            return {
                ...state,
                forgetPassLoading: !state.forgetPassLoading
            };
        case Actions.LOGIN_USER_LOADING:
            return {
                ...state,
                loginLoading: !state.loginLoading
            };
        case Actions.GET_USER_LOADING:
            return {
                ...state,
                userLoading: !state.userLoading
            };
        case Actions.ADD_USER_LOADING:
            return {
                ...state,
                addUserLoading: !state.addUserLoading
            };
        case Actions.GET_USER:
            return {
                ...state,
                user: payload,
                userLoading: false
            };

        case Actions.REGISTER_USER_LOADING:
            return {
                ...state,
                registerLoading: !state.registerLoading
            };
        case Actions.LOGOUT_USER:
            localStorage.removeItem('role');
            localStorage.removeItem('config');
            localStorage.removeItem('websiteId');
            return {
                ...initialState
            };

        case Actions.UPDATE_USER_PROFILE_LOADING:
            return { ...state, updateUserProfileLoading: !state.updateUserProfileLoading };
        case Actions.UPDATE_USER_PROFILE:
            return { ...state, updateUserProfileLoading: false, user: payload };

        default:
            return state;
    }
};

export default auth;
