import * as Actions from '../../actions/admin/ccpaRequest.action';

const initialState = {
    requestsLoading: false,
    delrequestLoading: false,
    requests: [],
    count: 0,
    pages: 0,
    limit: 0,
    page: 0
};

const invoices = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case Actions.GET_CCPA_REQUEST_LOADING:
            return { ...state, requestsLoading: !state.requestsLoading };
        case Actions.DELETE_CCPA_REQUEST_LOADING:
            return { ...state, delrequestLoading: !state.delrequestLoading };
        case Actions.GET_CCPA_REQUEST:
            return {
                ...state,
                requests: payload.result,
                requestsLoading: false,
                pages: payload.pages,
                limit: payload.limit,
                page: payload.page
            };
        case Actions.DELETE_CCPA_REQUEST:
            let temp = state.requests;
            temp = temp.filter((temp) => temp.id !== payload);
            return {
                ...state,
                requests: temp,
                delrequestLoading: false
            };
        default:
            return state;
    }
};

export default invoices;
