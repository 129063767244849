import React from 'react';
import { Box, Select, MenuItem, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getSidebarWebsites, setDefaultWebsite } from 'store/actions/sidebar.actions';

const Websiteselector = () => {
    const [website, setWebsite] = React.useState('');
    const { websites, websitesLoading } = useSelector((state) => state.sidebar);

    const dispatch = useDispatch();
    // React.useEffect(() => {
    //     dispatch(getSidebarWebsites());
    // }, []);
    const handleChange = (e) => {
        const { value } = e.target;
        setWebsite(value);
    };
    React.useEffect(() => {
        dispatch(setDefaultWebsite(website));
    }, [website]);

    React.useEffect(() => {
        if (websites.length > 0) setWebsite(websites[0].id);
    }, [websites]);

    if (websites.length < 1) {
        return null
    }
    else {
        return (
            <Box sx={{ display: { xs: 'block', md: 'block' }, padding: '20px' }}>
                <Typography align='center' style={{ fontSize: '14px', fontWeight: '500' }}>Websites</Typography>
                <Select
                    fullWidth
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label=""
                    value={website}
                    style={{ height: '40px', padding: ' 0 5px', marginTop: '5px' }}
                    placeholder="Select default website"
                    onChange={handleChange}
                >
                    {websites.length > 0 ? (
                        websites.map((site) => {
                            return (
                                <MenuItem key={site.id} value={site.id}>
                                    {site.website}
                                </MenuItem>
                            );
                        })
                    ) : (
                        <Typography sx={{ padding: '10px' }}>No website added</Typography>
                    )}
                </Select>
            </Box>
        );
    };
}
export default Websiteselector;
