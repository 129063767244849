import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from 'source/constants';
import { getToken } from 'AuthWrapper';

export const INVOICES_LOADING = 'INVOICES_LOADING';

export const GET_INVOICES = 'GET_INVOICES';

export const DELETE_INVOICES = 'DELETE_INVOICES';

export const EDIT_INVOICES = 'EDIT_INVOICES';

export const getInvoices = (data) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: INVOICES_LOADING
    });
    try {
        axios.defaults.headers.common['Authorization'] = token;

        const params = {
            ...data
        };
        const res = await axios.get(`${BASE_URL}/admin/invoice`, { params });
        if (res.data) {
            dispatch({
                type: GET_INVOICES,
                payload: res.data
            });
        }
        return res.data;
    } catch (error) {
        toast.error('Unable to get Invoices');
        dispatch({
            type: INVOICES_LOADING
        });
    }
};

export const editInvoices = (id) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: INVOICES_LOADING
    });
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.put(`${BASE_URL}/admin/invoice?limit=10&page=1`, { id });
        if (res.data) {
            dispatch({
                type: EDIT_INVOICES,
                payload: res.data
            });
        }
        return res.data;
    } catch (error) {
        toast.error('Unable to get users');
        dispatch({
            type: INVOICES_LOADING
        });
    }
};

export const deleteInvoices = (id) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: INVOICES_LOADING
    });
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.post(`${BASE_URL}/admin/invoice?limit=10&page=1`, { id });
        if (res.data) {
            dispatch({
                type: DELETE_INVOICES,
                payload: res.data
            });
        }
        return res.data;
    } catch (error) {
        toast.error('Unable to get users');
        dispatch({
            type: INVOICES_LOADING
        });
    }
};
