
import CardTable from './usersTable.jsx';


const FreeUsers = () => {

    return (
        <>
            <CardTable />
        </>
    );
};

export default FreeUsers;
