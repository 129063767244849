const role = localStorage.getItem('role');

const config = {

    defaultPath: `${role}/dashboard`,
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12
};

export default config;
