import * as Actions from '../../actions/admin/subscriptions.action';

const initialState = {
    subscriptionsLoading: false,
    userSubscriptions: [],
    count: 0,
    pages: 0,
    limit: 0,
    page: 0
};

const subscriptions = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case Actions.SUBSCRIPTIONS_LOADING:
            return { ...state, subscriptionsLoading: !state.subscriptionsLoading };
        case Actions.GET_SUBSCRIPTIONS:
            return {
                ...state,
                userSubscriptions: payload.result,
                subscriptionsLoading: !state.subscriptionsLoading,
                pages: payload.pages,
                limit: payload.limit,
                page: payload.page
            };
        case Actions.EDIT_SUBSCRIPTIONS:
            return { ...state, userSubscriptions: payload.result, subscriptionsLoading: !state.subscriptionsLoading };
        case Actions.DELETE_SUBSCRIPTIONS:
            return { ...state, userSubscriptions: payload.result, subscriptionsLoading: !state.subscriptionsLoading };
        default:
            return state;
    }
};

export default subscriptions;
