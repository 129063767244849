import { Pagination, Box, Select, MenuItem, Typography } from '@mui/material';

import React from 'react';
import { useTheme } from '@mui/material/styles';

import './TablePagination.css';
const TablePagination = ({ rowsPerPageOptions, component, count, page, rowsPerPage, onPageChange, onRowsPerPageChange }) => {
    const theme = useTheme();

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    marginTop: '10px',

                    flexDirection: 'row',
                    padding: '20px',
                    [theme.breakpoints.down('sm')]: {
                        flexDirection: 'column'
                    }
                }}
            >
                <Box
                    style={{
                        width: '200px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Typography style={{ fontSize: '14px', color: 'black' }}>Set row limit</Typography>

                    <Box style={{ marginLeft: '5px' }}>
                        <Select
                            className="select"
                            value={rowsPerPage}
                            onChange={onRowsPerPageChange}
                            sx={{
                                minHeight: '16px',
                                fontSize: '14px',
                                width: '60px',
                                '& .css-1ojzxaw-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input': { padding: '5px' },
                                boxShadow: 'none',
                                '.MuiOutlinedInput-notchedOutline': { border: 0 }
                            }}
                        >
                            {rowsPerPageOptions.map((item) => {
                                return (
                                    <MenuItem style={{ fontSize: '14px', padding: '5px', color: 'black' }} value={item}>
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Box>
                </Box>
                <Pagination
                    sx={{
                        [theme.breakpoints.down('sm')]: {
                            marginTop: '10px'
                        }
                    }}
                    count={count}
                    variant="outlined"
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChange={(e, newPage) => onPageChange(e, newPage)}
                    onRowsPerPageChange={onRowsPerPageChange}
                    rowsPerPageOptions={rowsPerPageOptions}
                />
            </Box>
        </>
    );
};

export default TablePagination;
