import {
    IconLink,
    IconSquarePlus,
    IconEye,
    IconSettings,
    IconDatabase,
    IconFileInvoice,
    IconBoxMultiple1,
    IconReceipt2,
    IconListDetails,
    IconHome,
    IconMailbox,
    IconUserCircle
} from '@tabler/icons';
import React from 'react';

// ==============================|| MENU ITEMS ||============================== //

// const menuSelection = () => {
//     const role = localStorage.getItem('role');
//     console.log('ROLE IN ITEMS: ', role);
//     if (role === 'admin') {
//         return adminMenu;
//     } else  {
//         return menuItems;
//     }
// };

export const menuItems = {
    items: [
        {
            id: 'dashboard',
            type: 'group',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: `dashboard`,
                    icon: IconListDetails,
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'Websites',

            type: 'group',
            icon: 'IconLink',
            children: [
                {
                    id: 'view',
                    title: 'View Websites',
                    type: 'item',
                    url: `websites`,
                    icon: IconEye,
                    breadcrumbs: false
                },
                {
                    id: 'add',
                    title: 'Add Websites',
                    type: 'item',
                    url: `add/website`,
                    icon: IconSquarePlus,
                    breadcrumbs: true
                }
            ]
        },
        // {
        //     id: 'template',
        //     type: 'group',
        //     children: [
        //         {
        //             id: 'template',
        //             title: 'Template',
        //             type: 'item',
        //             url: `template`,
        //             icon: IconListDetails,
        //             breadcrumbs: false
        //         }
        //     ]
        // },

        {
            id: 'billing',
            type: 'group',
            children: [
                {
                    id: 'billing',
                    title: 'billings',
                    type: 'item',
                    url: `billing`,
                    icon: IconReceipt2,
                    breadcrumbs: false
                }
                // {
                //     id: 'billing',
                //     title: 'billings',
                //     type: 'item',
                //     url: `billing`,
                //     icon: IconReceipt2,
                //     breadcrumbs: false
                // }
            ]
        },
        // {
        //     id: 'template',
        //     type: 'group',
        //     children: [
        //         {
        //             id: 'template',
        //             title: 'Template',
        //             type: 'item',
        //             url: `template`,
        //             icon: IconListDetails,
        //             breadcrumbs: false
        //         }
        //     ]
        // },

        {
            id: 'request',
            type: 'group',
            children: [
                {
                    id: 'CCPARequests',
                    title: 'CCPA Requests',
                    type: 'item',
                    url: `ccpa-requests`,
                    icon: IconMailbox,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'template-options',
            type: 'group',
            children: [
                {
                    id: 'template-options',
                    title: 'Template Configs',
                    type: 'item',
                    url: `template-configs`,
                    icon: IconMailbox,
                    breadcrumbs: true
                }
            ]
        },
        {
            id: 'setting',
            type: 'group',
            children: [
                {
                    id: 'setting',
                    title: 'Settings',
                    type: 'item',
                    url: `setting`,
                    icon: IconSettings,
                    breadcrumbs: false
                }
            ]
        },
    ]
};

export const adminMenu = {
    items: [
        {
            id: 'Users  ',
            title: '',
            type: 'group',
            icon: 'IconLink',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: `dashboard
                    `,
                    icon: IconHome,
                    breadcrumbs: false
                },
                {
                    id: 'usersData',
                    title: 'Users',
                    type: 'item',
                    url: `users`,
                    icon: IconDatabase,
                    breadcrumbs: false
                },
                {
                    id: 'freeUsers',
                    title: 'Free Users',
                    type: 'item',
                    url: `free-users`,
                    icon: IconUserCircle,
                    breadcrumbs: false
                },
                {
                    id: 'websites',
                    title: 'Websites',
                    type: 'item',
                    url: `websites`,
                    icon: IconBoxMultiple1,
                    breadcrumbs: true
                },
                {
                    id: 'invoice',
                    title: 'Invoices',
                    type: 'item',
                    url: `invoices`,
                    icon: IconFileInvoice,
                    breadcrumbs: true
                },

                {
                    id: 'subscriptions',
                    title: 'Subscriptions',
                    type: 'item',
                    url: `subscriptions`,
                    icon: IconSquarePlus,
                    breadcrumbs: true
                },

                {
                    id: 'Template Builds',
                    title: 'Template Builds',
                    type: 'item',
                    url: `template-builds`,
                    icon: IconSquarePlus,
                    breadcrumbs: true
                }
            ]
        }
    ]
};

// export default menuSelection();
