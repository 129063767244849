import * as React from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    IconButton,
    CircularProgress,
    TextField,
    Button,
} from '@mui/material';
import { IconCheckbox, IconSquareX } from '@tabler/icons';
import FormTablePagination from '../../TableComponents/TablePagination';
import { addFreeUser, delUser, getFreeUsers } from 'store/actions/admin/usersData.action';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/system';

const UsersTable = () => {

    const columns = [
        { id: 1, label: 'Name', width: 100 },
        { id: 2, label: 'Email', width: { xl: 150, lg: 150, md: 170, sm: 200, xs: 200 } },
        { id: 3, label: 'Verified', width: 100 },
        { id: 4, label: 'Id', width: 150 },
        { id: 5, label: '', width: 150 },


        // { id: 4, label: 'Actions', width: 100 }
    ];

    const [data, setData] = React.useState({
        users: [],
        pages: 0,
        count: 0
    })

    const [page, setPage] = React.useState(1)
    const [usersLoading, setUsersLoading] = React.useState(true);
    const [userId, setUserId] = React.useState('');

    const [addLoading, setAddLoading] = React.useState(false);
    const [deleteLoading, setDeleteLoading] = React.useState(false);


    const dispatch = useDispatch();

    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    function getData(rs, pg) {
        setUsersLoading(true)
        dispatch(getFreeUsers({
            limit: rs,
            page: pg
        })).then(data => {
            if (data) {
                setData({
                    users: data.result,
                    pages: data.pages,
                    count: data.count
                })
                setUsersLoading(false)
            }
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getData(rowsPerPage, newPage)
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        getData(event.target.value, page)
    };

    React.useEffect(() => {
        getData(rowsPerPage, page);
    }, [])

    function handleAddUser(e) {
        e.preventDefault()
        setAddLoading(true);
        dispatch(addFreeUser(userId)).then(data => {
            setAddLoading(false)
            getData(rowsPerPage, page);
        }).catch(err => {
            setAddLoading(false)
        })
    }

    function handleDelUser(id) {
        setDeleteLoading(true);
        dispatch(delUser(id)).then(data => {
            setDeleteLoading(false)
            getData(rowsPerPage, page);
        }).catch(err => {
            setDeleteLoading(false)
        })
    }

    return (
        <>
            <Box display='flex' component='form' onSubmit={handleAddUser} value={userId} onChange={(e) => setUserId(e.target.value)} alignItems='center' flexWrap='wrap' gap={3} my={3}>
                <TextField variant='outlined' label='Enter User Id' />
                <Button variant='contained' color='primary' type='submit' disabled={addLoading}> Add User</Button>
            </Box>
            <Paper sx={{ width: '100%', overflow: 'hidden', padding: '20px' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table" style={{ tableLayout: 'fixed' }}>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} sx={{ width: column.width }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {usersLoading ? (
                                <>
                                    <TableRow>
                                        <TableCell colSpan="5" align="center">
                                            <CircularProgress size={32} />
                                        </TableCell>
                                    </TableRow>
                                </>
                            ) : (
                                <>
                                    {data.users.length > 0 ? (
                                        data.users.map((row) => {
                                            return (
                                                <TableRow key={row.id}>
                                                    <TableCell>
                                                        <Typography>{row.name ? row.name : 'None'}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>{row.email ? row.email : 'None'}</Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        {' '}
                                                        <Typography>
                                                            {row.emailVerified ? <IconCheckbox color="green" /> : <IconSquareX color="red" />}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        {' '}
                                                        <Typography>
                                                            {row.id}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button variant='contained' disabled={deleteLoading} onClick={() => handleDelUser(row.id)} color='primary'>Delete</Button>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan="5" align="center">
                                                No Free Users
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!usersLoading ? (
                    <FormTablePagination
                        component="div"
                        rowsPerPageOptions={[5, 10, 25, 100]}
                        count={data.pages}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                ) : (
                    <></>
                )}
            </Paper>
        </>
    );
};

export default UsersTable;