import axios from 'axios';
import { toast } from 'react-toastify';
import { BASE_URL } from 'source/constants';
import { getToken } from 'AuthWrapper';

export const USERS_DATA_LOADING = 'USERS_DATA_LOADING';
export const GET_USERS = 'GET_USERS';
export const DELETE_USER = 'DELETE_USER';
export const EDIT_USER = 'EDIT_USER';

export const getUsers = (data) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: USERS_DATA_LOADING
    });
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const params = {
            ...data
        };
        const res = await axios.get(`${BASE_URL}/admin/users`, { params });
        if (res.data) {
            dispatch({
                type: GET_USERS,
                payload: res.data
            });
        }
        return res.data;
    } catch (error) {
        toast.error('Unable to get users');
        dispatch({
            type: USERS_DATA_LOADING
        });
    }
};

export const getFreeUsers = (data) => async (dispatch) => {
    const token = await getToken();
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const params = {
            ...data
        };
        const res = await axios.get(`${BASE_URL}/admin/free-users`, { params });
        if (res.data) {
            return res.data
        }
        return res.data;
    } catch (error) {
        toast.error(err.reponse.data.message);
    }
};

export const editUser = (id) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: USERS_DATA_LOADING
    });
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.get(`${BASE_URL}/admin/users?limit=10&page=1`, { id });
        if (res.data) {
            dispatch({
                type: EDIT_USER,
                payload: res.data
            });
        }
        return res.data;
    } catch (error) {
        toast.error('Unable to get users');
        dispatch({
            type: USERS_DATA_LOADING
        });
    }
};

export const deleteUser = (id) => async (dispatch) => {
    const token = await getToken();
    dispatch({
        type: USERS_DATA_LOADING
    });

    console.log(id);
    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.get(`${BASE_URL}/admin/users?limit=10&page=1`, { id });
        if (res.data) {
            dispatch({
                type: DELETE_USER,
                payload: res.data
            });
        }
        return res.data;
    } catch (error) {
        toast.error('Unable to get users');
        dispatch({
            type: USERS_DATA_LOADING
        });
    }
};


export const addFreeUser = (userId) => async (dispatch) => {
    const token = await getToken();

    try {
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.post(`${BASE_URL}/admin/free-user`, { userId });
        if (res.data) {
            toast.success(res.data.message);

            return res.data
        }
        return res.data;
    } catch (error) {
        toast.error(error.response.data.message);

    }
};




export const delUser = (userId) => async (dispatch) => {
    const token = await getToken();

    try {
        const params = {
            userId
        };
        axios.defaults.headers.common['Authorization'] = token;
        const res = await axios.delete(`${BASE_URL}/admin/remove-free-user`, { params });
        if (res.data) {

            toast.info(res.data.message);
            return res.data
        }
    } catch (error) {
        toast.error(error.response.data.message);
    }
};