import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyC07POQraBc3WwuEk2K_N_i9LAgjpknIgQ",
  authDomain: "privacy.allyright.com",
  projectId: "privacy-suite-prod",
  storageBucket: "privacy-suite-prod.appspot.com",
  messagingSenderId: "488841926925",
  appId: "1:488841926925:web:3b05574d0ead9938a2b844",
  measurementId: "G-7R22KEM3SW"
};
const app = initializeApp(firebaseConfig);
const db = getFirestore();
export { app, db };