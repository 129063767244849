import * as Actions from '../actions/template.actions';

const initialState = {
    updatePolicyLoading: false,
    getPolicyLoading: false,
    policy: null
};

const policy = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case Actions.UPDATE_POLICY_LOADING:
            return {
                ...state,
                updatePolicyLoading: !state.updatePolicyLoading
            };
        case Actions.GET_POLICIES_LOADING:
            return {
                ...state,
                getPolicyLoading: !state.getPolicyLoading
            };
        case Actions.GET_POLICIES:
            return {
                ...state,
                policy: payload,
                getPolicyLoading: false
            };
        case Actions.UPDATE_POLICY:
            return {
                ...state,
                updatePolicyLoading: false
            };
        default:
            return state;
    }
};

export default policy;
