import React from 'react';
import Loader from './Loader';
import { CircularProgress } from '@mui/material';

const SplashScreen = () => {
    return (
        <div
            style={{
                background: 'aliceblue',
                height: '100vh',
                padding: '20px',
                margin: 'auto',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <CircularProgress />
            <Loader />
        </div>
    );
};

export default SplashScreen;
